import { ethers } from 'ethers'

const abi = [
  'function getFtsoWhitelistedPriceProvidersBySymbol(string memory _symbol) external view returns (address[] memory)'
]

export const VoterWhitelisterAbi = new ethers.utils.Interface(abi)

export function getVoterWhitelisterContract(address, provider) {
  return new ethers.Contract(address, VoterWhitelisterAbi, provider)
}
