import React from 'react'
import { ethers } from 'ethers'
import { ChainId, networks } from '../const'

import { useEthers, useEtherBalance, shortenAddress } from '@usedapp/core'

import sgbImage from '../../images/sgb.png'
import flrImage from '../../images/flr.png'

function TopBar() {
  const { chainId, account } = useEthers()
  const balance = useEtherBalance(account)

  return (
    <div className="mb-12 w-full flex-col items-end justify-end">
      <div className="flex items-center justify-end text-xl font-bold mb-2">
        <img
          src={ChainId[chainId] === 'Songbird' ? sgbImage : flrImage}
          alt=""
          width={24}
          height={24}
          className="inline mx-2 w-6 h-full"
        />
        <span className="mr-2">{ChainId[chainId]}</span>
      </div>
      <div className="flex items-center justify-end text-xl font-bold gap-8" >
        <div className="text-xl font-extrabold">
          <span className="font-semibold">Account: </span>
          {account && shortenAddress(account)}
        </div>
        <div className="text-xl font-extrabold">
          <span className="font-semibold">Balance: </span>
          {balance &&
            `${ethers.utils.commify(Number(ethers.utils.formatEther(balance)).toFixed(4))} ${
              networks[chainId].nativeCurrency.symbol
            }`}
        </div>
      </div>
    </div>
  )
}

export default TopBar
