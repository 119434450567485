function stepReducer(state, action) {
  let newState
  switch (action.type) {
    case 'NEXT':
      if (state.step >= 5) {
        newState = { step: 5 }
      } else {
        newState = { step: state.step + 1 }
      }
      break
    case 'PREV':
      if (state.step <= 1) {
        newState = { step: 1 }
      } else {
        newState = { step: state.step - 1 }
      }
      break
    case 'SET':
      newState = { step: action.step }
      break
    default:
      throw new Error('stepReducer Error ' + JSON.stringify({ state, action }))
  }
  return newState
}

export default stepReducer
