import React, { useContext, useEffect, useState } from 'react'

import SelectedDelegatesContext from '../../context/SelectedDelegatesContext'

const logoSize = 36

function ProviderEntry({ provider, onChange, isLightFTSO=false }) {
  const { state, dispatch } = useContext(SelectedDelegatesContext)
  const [highlight, setHighlight] = useState(false)

  const isChecked = !!state.find(
    (p) => p.address.toLowerCase() === provider.address.toLowerCase()
  )

  return (
    <label className={`block my-1 bg-gray-100 rounded text-gray-800 font-semibold w-full py-2 px-4 flex flex-row items-center ${state.length < 2 && 'hover:bg-yellow-300'} transition ${isChecked && 'bg-yellow-200'}`}>
      <span className="mr-2">
        <img width={logoSize} height={logoSize} alt="" src={provider.logoURI} className="inline-block" style={{ width: logoSize, height: logoSize }} />
      </span>
      <span className="mr-2">{provider.name} {isLightFTSO && <span className='mx-4 text-sm'>Please select me 😄</span>}</span>
      
      <input
        className="hidden"
        type="checkbox"
        checked={
          !!state.find((p) => p.address.toLowerCase() === provider.address.toLowerCase())
        }
        onClick={() => {
          if (isChecked) {
            dispatch({ type: 'REMOVE', payload: { address: provider.address } })
          }
        }}
        onChange={(e) => {
          if (e.target.checked) {
            dispatch({
              type: 'ADD',
              payload: { address: provider.address.toLowerCase(), percent: 0 }
            })
            setHighlight(true)
          } else {
            dispatch({ type: 'REMOVE', payload: { address: provider.address } })
            setHighlight(false)
          }
        }}
      />
    </label>
  )
}

export default ProviderEntry
