import React from 'react'

export const defaultValue = {
  knownProviders: [],
  lightFtsoAddress: '0xfb9197720329a80191ba140844e96dccad149014',
  selectedDelegations: [],
  setSelectedDelegations: () => {}
}

const DelegateContext = React.createContext(defaultValue)
DelegateContext.displayName = 'DelegateContext'

export default DelegateContext

