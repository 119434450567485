import React, { useContext, useState, useEffect } from 'react'
import { useEthers, useContractFunction } from '@usedapp/core'
import { ethers } from 'ethers'

import Spinner from '../../../../partials/Spinner'
import DelegateContext from '../context/DelegateContext'
import { FlareContext } from '../../DappWrapper'
import { getProviderData } from '../../../const/knownProviders'

const logoSize = 36

function DelegationCTA({ index, address, percent, provider, account, contracts }) {
  const { state: txState, send: sendTx } = useContractFunction(
    contracts?.Wnat,
    'delegate',
    { transactionName: `Delegate to ${provider?.name || address}` }
  )

  const txStatusMap = {
    None: '',
    Mining: 'Awaiting confirmation...',
    Success: 'Success!',
    Failed: 'Transaction unsuccesful',
    Exception: ''
  }

  const delegate = () => {
    console.debug('Sending delegation tx', {
      accountOrigin: account,
      provider: { address, name: provider?.name },
      percent,
      bips: ethers.utils.parseUnits(percent.toString(), 2)
    })
    sendTx(address, ethers.utils.parseUnits(percent.toString(), 2))
  }

  return (
    <div className={`block my-1 bg-gray-100 rounded text-gray-800 font-semibold w-full py-2 px-4 flex flex-row items-center transition`}>
      <div className='mr-4 font-semibold text-black text-xl'>{index + 1}.</div>
      <div className='w-64 text-ellipsis'>
        <span className="mr-2">
          {provider?.logoURI && <img width={logoSize} height={logoSize} alt="" src={provider?.logoURI} className="inline-block" style={{ width: logoSize, height: logoSize }} />}
        </span>
        <span>{provider?.name || `${address.slice(0, 6)}${'...'}${address.slice(-4)}`}</span>
      </div>
      <div className='w-18 text-bold mx-6 text-xl'>{percent}%</div>
      <div className='w-32 mx-4'>
        <button
          onClick={delegate}
          className={`h-10 w-full flex items-center justify-center rounded bg-green-400 hover:bg-green-500 py-2 px-5  text-white font-semibold ${txState.status === 'Success' ? 'hidden' : ''}`}>
          {txState.status === 'Mining' ? <Spinner size={16} /> : 'Delegate!'}
        </button>
      </div>
      <div>{txStatusMap[txState.status]}</div>
      <div className='text-xs text-gray-600 w-72'>{txState.errorMessage}</div>
    </div >
  )
}

function ConfirmDelegations({ stepInfo, stepHandler }) {
  const { account, chainId } = useEthers()
  const { contracts } = useContext(FlareContext)
  const { selectedDelegations, setSelectedDelegations, lightFtsoAddress } = useContext(DelegateContext)

  const [knownProviders, setKnownProviders] = useState([])

  const _getProviders = async () => {
    getProviderData()
      .then(providers => [setKnownProviders(providers)])
      .catch(() => { })
  }
  useEffect(() => {
    _getProviders()
  }, [])

  return <div className="py-0 flex flex-col w-full justify-center items-center">
    <div className="relative w-full text-center text-2xl font-semibold mb-6">
      <button
        className='absolute left-0 rounded border-1 border-yellow-400 border-solid text-yellow-400 text-base mr-12'
        onClick={() => [setSelectedDelegations([]), stepHandler.prev()]}>⬅️ Make changes
      </button>
      <span>{stepInfo.title}</span>
    </div>
    <div className="text-center text-2xl font-semibold mb-6">

    </div>
    {selectedDelegations.map(p => p.address).find(p => p.toLowerCase() == lightFtsoAddress.toLowerCase()) &&
      <div className='w-full text-left font-semibold mb-3'>Thank you very much for choosing <span className="mx-0 dark font-red-hat-display">
        Light<span className="text-yellow-500">FTSO</span></span> in your delegations!</div>
    }
    <div className='flex flex-col items-center justify-center w-full'>
      {selectedDelegations.map(({ address, percent }, i) =>
        <DelegationCTA key={address} index={i} account={account} contracts={contracts} address={address} percent={percent} provider={knownProviders?.find(
          (p) => p?.address?.toLowerCase() === address.toLowerCase())} />
      )}
    </div>
  </div>
}

export default ConfirmDelegations
