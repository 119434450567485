function selectedDelegationsReducer(selectedDelegations, action) {
  let newState

  const index = selectedDelegations.findIndex((p) => p.address == action.payload.address.toLowerCase())

  switch (action.type) {
    case 'ADD':
      if (index > -1 || selectedDelegations.length >= 2) {
        newState = selectedDelegations
        break
      } else {
        newState = [...selectedDelegations, action.payload]
      }
      break
    case 'REMOVE':
      if (index > -1) {
        newState = selectedDelegations.filter(
          (p) => p.address.toLowerCase() !== action.payload.address.toLowerCase()
        )
        break
      } else {
        newState = selectedDelegations
      }
      break
    case 'SET_PERCENT':
      if (index > -1) {
        return selectedDelegations.map((provider) => provider.address === action.payload.address ?
          { ...provider, percent: action.payload.percent } : provider)
      }
      break

    default:
      return selectedDelegations
  }
  return newState
}

export default selectedDelegationsReducer
