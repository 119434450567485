import React, { useEffect, useContext } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

import { LocaleContext } from './locale/LocaleContext'

import './css/style.scss'

import AOS from 'aos'
import { focusHandling } from 'cruip-js-toolkit'

import Home from './pages/Home'
// import About from './pages/About'
import Disclaimer from './pages/Disclaimer'
import PrivacyPolicy from './pages/PrivacyPolicy'
import DAppPage from './pages/DAppPage'
import PageNotFound from './pages/PageNotFound'
import DappPage from './pages/DAppPage'

import Giveaway from './pages/Giveaway'
import GiveawayLinkModal from './partials/GiveawayLinkModal'

import Rewards from './ftso/dapps/Rewards'
import Wrap from './ftso/dapps/Wrap'
import Delegate from './ftso/dapps/Delegate/index'

import langEn from './compiled-lang/en.json'
import langEs from './compiled-lang/es.json'

const messages = { en: langEn, es: langEs }

function App() {
  const location = useLocation()
  const [locale] = useContext(LocaleContext)

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 750,
      easing: 'ease-out-quart'
    })
  })

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline')
  }, [location.pathname]) // triggered on route change

  return (
    <>
      <IntlProvider locale={locale} defaultLocale="en" messages={messages[locale]}>
        {/*<GiveawayLinkModal />*/}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/disclaimer">
            <Disclaimer />
          </Route>
          <Route exact path="/delegate/songbird">
            <DAppPage network="SGB" App={Delegate} />
          </Route>
          <Route exact path="/delegate/flare">
            <DAppPage network="FLR" App={Delegate} />
          </Route>
          <Route exact path="/wrap">
            <DAppPage App={Wrap} />
          </Route>
          <Route exact path="/rewards">
            <DappPage App={Rewards} />
          </Route>
         {/*  <Route exact path="/giveaway">
            <Giveaway />
          </Route>*/}
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </IntlProvider>
    </>
  )
}

export default App
