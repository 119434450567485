import React, { useState, useEffect } from 'react'

import Modal from '../utils/Modal'

import ChristmasSongbird from '../images/christmas-songbird.png'

function GiveawayLinkModal({ show, handleClose }) {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!window.sessionStorage.getItem('seenGiveawayModal')) setModalOpen(true)
    }, 1500)
  }, [])

  if (window.location.pathname === '/giveaway') {
    return null
  }

  return (
    <Modal
      id="1mil-giveaway-modal"
      ariaLabel="1mil-giveaway-modal"
      show={modalOpen}
      handleClose={() => [
        window.sessionStorage.setItem('seenGiveawayModal', true),
        setModalOpen(false)
      ]}
      className="bg-gray-200 overflow-auto max-w-4xl w-full max-h-3/4 lg:max-h-full rounded">
      <div className="w-full h-full p-6  bg-scroll bg-contain bg-no-repeat bg-bottom bg-clip-position pb-80	" style={{ backgroundImage: `url("${ChristmasSongbird}")` }}>
        <div>
          <div className="text-red-600 font-bold text-center text-3xl lg:text-4xl mb-4">
            5000 SGB Christmas Giveaway!
          </div>
          <div className="text-red-600 font-bold text-center text-3xl lg:text-4xl mb-4">
            Happy Christmas!
          </div>
          <div className="filter drop-shadow-lg text-gray-900 font-bold text-center lg:text-xl px-4 mt-8 mb-4 lg:mb-2">
            LightFTSO is proud to offer a giveaway of a total of 5000 SGB (yes, five thousand!) to the Songbird community.
            <br />
            All you have to do is
          </div>
          <div className="text-2xl lg:text-3xl text-red-600  pointer font-bold w-full text-center lg:px:8 mb-12">
            <a href="/giveaway" target="_self" className='shadow-red-700'>
              Click here to participate!
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GiveawayLinkModal
