import React from 'react'

export const LocaleContext = React.createContext(['en', () => {}])
LocaleContext.displayName = 'LocaleContext'

export const LocaleContextProvider = ({ children }) => {
  const [locale, setLocale] = React.useState(
    navigator.languages.find((e) => e === 'es') || 'en'
  )
  return (
    <LocaleContext.Provider value={[locale, (locale) => setLocale(locale)]}>
      {children}
    </LocaleContext.Provider>
  )
}
