import React, { useState, useEffect } from 'react'
import { useEthers } from '@usedapp/core'

import { getWnatContract } from '../contracts/wnat'
import { getFtsoRewardManagerContract } from '../contracts/ftsorewardmanager'
import { getFtsoManagerContract } from '../contracts/ftsomanager'
import { getPriceSubmitterContract } from '../contracts/pricesubmitter'
import { getVoterWhitelisterContract } from '../contracts/voterwhitelister'

import { addresses } from '../contracts'

export const FlareContext = React.createContext({
  contracts: {},
})

FlareContext.displayName = 'FlareContext'

function DappWrapper({ children }) {
  const { chainId, library: provider } = useEthers()
  const [flareContextValue, setFlareContextValue] = useState({})

  useEffect(() => {
    const contracts = {
      FtsoManager: getFtsoManagerContract(addresses[chainId].FtsoManager, provider),
      FtsoRewardManager: getFtsoRewardManagerContract(
        addresses[chainId].FtsoRewardManager,
        provider
      ),
      VoterWhitelister: getVoterWhitelisterContract(
        addresses[chainId].VoterWhitelister,
        provider
      ),
      PriceSubmitter: getPriceSubmitterContract(provider),
      Wnat: getWnatContract(addresses[chainId].Wnat, provider)
    }

    setFlareContextValue({ contracts })

    return () => {
      setFlareContextValue({ contracts: {} })
    }
  }, [provider, chainId])

  return (
    <FlareContext.Provider value={flareContextValue}>{children}</FlareContext.Provider>
  )
}

export default DappWrapper
