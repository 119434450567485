import React, { useContext, useState, useEffect } from 'react'
import SelectedDelegatesContext from '../../context/SelectedDelegatesContext'

const logoSize = 36

const Selection = ({ availableProviders, address, percent, onSetPercent }) => {
  const { state, dispatch } = useContext(SelectedDelegatesContext)


  const [bipsValue, setBipsValue] = useState(percent)
  const remaining = 100 - state.map(p => p.percent).reduce((a, b) => a + b, 0)
  const max = remaining + bipsValue

  const [provider] = useState(() =>
    availableProviders.find((p) => p.address.toLowerCase() === address.toLowerCase())
  )

  const onChangeSelectedBips = (bips) => {
    if (isNaN(bips)) bips = 0
    bips = Math.ceil(parseFloat(bips))
    if (bips < 1) bips = 0
    if (bips > max) bips = max

    setBipsValue(bips)
    onSetPercent(address, bips)
  }

  return (
    <div className='flex flex-row items-center justify-between relative bg-gray-100 w-full py-2 px-4 my-1 rounded text-gray-800 font-semibold'>
      <div><span className="mr-2">
        <img
          width={logoSize}
          height={logoSize}
          alt=""
          src={provider.logoURI}
          className="inline-block"
          style={{ width: logoSize, height: logoSize }}
        />
      </span>
        <span className='mr-4'>
          {provider.name}
        </span>
      </div>
      <div>
        <span className='mr-4 font-semibold'>
          <input
            type='number'
            min={0}
            max={100}
            step="1"
            value={bipsValue}
            onChange={(e) => onChangeSelectedBips(e.target.value)}
            className="p-0 border-1 border-solid border-gray-400 rounded bg-transparent w-16 px-2 outline-0 hover:outline-0 focus:outline-0 text-lg font-semibold" />
          <span className="ml-2 font-semibold">%</span>
        </span>

        <div className='inline-block w-48'>
          {remaining > 0 && <button className='underline mx-8' onClick={() => onChangeSelectedBips(remaining + bipsValue)}>Use remaining</button>}
        </div>
        <button className='inline-flex flex items-center justify-center px-4 py-0.5 text-base bg-red-500 text-white rounded text-center' onClick={() => dispatch({ type: 'REMOVE', payload: { address } })}>
          <span className='inline'>Remove</span>
        </button>
      </div>
    </div>
  )
}

function Selections({ availableProviders, selectedDelegations, dispatch }) {
  //const { state: selectedDelegations, dispatch } = useContext(SelectedDelegatesContext)

  if (!selectedDelegations.length > 0) {
    return null
  }

  const onSetPercent = (address, percent) => {
    dispatch({ type: 'SET_PERCENT', payload: { address, percent } })
  }

  const remaining = 100 - selectedDelegations.map(p => p.percent).reduce((a, b) => a + b, 0)

  return (
    <div className="w-full p-4 flex flex-col items-center overflow-auto text-gray-100 font-semibold ">
      <div className="text-center text-2xl mt-6 mb-0">Selected providers</div>
      <div className="w-full text-left text-base text-gray-400 mt-0 mb-2">Set the percentage of wrapped SGB you want to delegate to each provider</div>
      {selectedDelegations.map(({ address, percent }) => {
        return (
          <Selection
            key={address}
            address={address}
            availableProviders={availableProviders}
            percent={percent}
            onSetPercent={onSetPercent}
          />
        )
      })}
    </div >
  )
}

export default Selections
