const networks = {
  19: {
    chainId: '0x13',
    chainName: 'Songbird',
    nativeCurrency: { decimals: 18, symbol: 'SGB' },
    rpcUrls: ['https://sgb.lightft.so/rpc', 'https://songbird.towolabs.com/rpc'],
    blockExplorerUrls: ['https://songbird-explorer.flare.network/']
  },
  14: {
    chainId: '0xD',
    chainName: 'Flare',
    nativeCurrency: { decimals: 18, symbol: 'FLR' },
    rpcUrls: []
  }
}

export default networks
