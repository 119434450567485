import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useEthers } from '@usedapp/core'
import { ethers } from 'ethers'
import _ from 'lodash'

import DelegateContext from '../context/DelegateContext'
import Wrap from '../../Wrap'
import Spinner from '../../../../partials/Spinner'
import { FlareContext } from '../../DappWrapper'

function CheckWnatBalance({ stepInfo, stepHandler }) {
  const { account, library: provider } = useEthers()
  const { contracts } = useContext(FlareContext)
  const [wnatBalance, setWnatBalance] = useState(0)

  const [loading, setLoading] = useState(true)

  const updateWnatBalance = useCallback(
    _.debounce(async () => {
      setLoading(true)
      const _wnatBalance = await contracts.Wnat.balanceOf(account)
      setWnatBalance(Number(ethers.utils.formatEther(_wnatBalance)))
      setTimeout(() => {
        setLoading(false)
      }, 250)
    }, 750),
    [wnatBalance]
  )

  const setWnatEvents = async () => {
    const filter = {
      address: contracts.Wnat.address,
      topics: [
        // the name of the event, parnetheses containing the data type of each event, no spaces
        ethers.utils.id('Deposit(address,uint256)')
      ]
    }
    provider.on(filter, async () => {
      await updateWnatBalance()
    })
  }

  useEffect(() => {
    updateWnatBalance()
    setWnatEvents()
  }, [])

  /* 
    useEffect(() => {
      if (wnatBalance > 0) {
        stepHandler.next()
      }
    }, [wnatBalance]) */

  if (loading) {
    return (
      <div className="py-12 h-auto flex flex-col justify-center items-center">
        <Spinner size={48} />
      </div>
    )
  }

  return (
    <div className="py-12 h-auto flex flex-col justify-center items-center">
      {wnatBalance <= 0 ? (
        <div className="flex flex-col items-center justify-center">
          <p className="text-lg font-bold mb-8">
            You don&lsquo;t have any wrapped SGB. You need WSGB to delegate and earn rewards.
          </p>
          <Wrap />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <div className="mb-8 text-lg font-bold">
            {ethers.utils.commify(wnatBalance)} WSGB available.
            <br />
            You have enough WSGB, please click{' '}
            <span className="font-extrabold">Continue</span>
          </div>
          <button
            className="btn text-white bg-yellow-500 hover:bg-yellow-400 w-36 flex items-center"
            onClick={stepHandler.next}>
            Continue
          </button>
        </div>
      )}
    </div>
  )
}

export default CheckWnatBalance
