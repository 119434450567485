import { ethers } from 'ethers'

const abi = [
  'function symbol() public view returns (string)',
  'function decimals() public view returns (uint8)',

  // wrap/unwrap
  'function deposit() public payable',
  'function withdraw(uint256 amount) external',

  // balance
  'function balanceOf(address account) public view returns (uint256)',

  // delegate
  'function delegationModeOf(address _who) external view returns (uint256)',
  'function delegate(address _to, uint256 _bips) external',
  'function delegateExplicit(address _to, uint _amount) external',
  'function delegatesOf(address _owner) external view returns (address[] memory _delegateAddresses, uint256[] memory _bips, uint256 _count, uint256 _delegationMode)',

  // undelegate
  'function undelegateAll() external override',

  // events
  'event Deposit(address indexed dst, uint amount)',
  'event Withdrawal(address indexed src, uint amount)'
]

export const WnatAbi = new ethers.utils.Interface(abi)

export function getWnatContract(address, provider) {
  return new ethers.Contract(address, WnatAbi, provider)
}
