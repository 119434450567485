import classNames from 'classnames'
import React from 'react'

function Header({ steps, stepHandler, currentStep }) {
  return (
    <div className="flex flex-col p-4 pt-0 mb-4">
      {process.env.NODE_ENV === 'development' && (
        <div className="flex flex-row border-1 border-yellow-300">
          <button className="mr-2" onClick={() => stepHandler.prev()}>
            Prev
          </button>
          <button onClick={() => stepHandler.next()}>Next</button>
        </div>
      )}
      <div className="flex flex-wrap">
        {steps.map((step, i) => {
          const active = currentStep === i + 1
          return (
            <div
              key={i}
              className={classNames(
                'mx-8 p-1 ',
                active && 'active border-b-2 border-b border-gray-50 transition duration-700 ease-in-out'
              )}>
              <span
                className={classNames(
                  'mr-2 text-xl',
                  active && 'font-semibold'
                )}>
                {i + 1}.
              </span>
              <span
                className={classNames(
                  'mr-2 text-xl',
                  active && 'font-semibold'
                )}>
                {step.headerTitle}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Header
