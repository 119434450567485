import React, { useContext, useState, useEffect } from 'react'
import { useEthers } from '@usedapp/core'

import { FlareContext } from '../DappWrapper'

import Spinner from '../../../partials/Spinner'
import Wizard from './Wizard'
import DelegateContext, { defaultValue } from './context/DelegateContext'
import { getProviderData } from '../../const/knownProviders'

function Delegate() {
  const { contracts } = useContext(FlareContext)

  const [delegateContextValue, setDelegateContextValue] = useState(defaultValue)
  const [knownProviders, setKnownProviders] = useState([])
  const [readyToRenderChildren, setReadyToRenderChildren] = useState(false)

  const _getProviders = async () => {
    getProviderData()
      .then(providers => [setKnownProviders(providers), setDelegateContextValue({ ...delegateContextValue, setSelectedDelegations, knownProviders: providers })])
      .catch((err) => [console.log(err), setDelegateContextValue({ ...delegateContextValue, setSelectedDelegations })])
      .finally(() => setReadyToRenderChildren(true))
  }

  const setSelectedDelegations = (selections) => {
    console.log(knownProviders)
    console.log({ ...delegateContextValue, knownProviders, selectedDelegations: selections })
    setDelegateContextValue({ ...delegateContextValue, selectedDelegations: selections })
  }

  useEffect(() => {
    _getProviders()

    const _delegateContextValue = {
      ...defaultValue,
      setSelectedDelegations
    }
    setDelegateContextValue(_delegateContextValue)
  }, [])

  if (!readyToRenderChildren || !contracts) {
    return <div className="p-24 md:p-16 lg:p-24 border-yellow-500 rounded border-2 mb-6 max-w-full flex justify-center items-center">
      <Spinner size={32} />
    </div>
  }

  return (
    <DelegateContext.Provider value={delegateContextValue}>
      <div className="p-8 pt-4 md:px-4 lg:px-8 border-yellow-500 rounded border-2 mb-6 max-w-full">
        <Wizard onNextStep={() => { if (!delegateContextValue.knownProviders?.length) _getProviders() }} />
      </div>
    </DelegateContext.Provider>
  )
}

export default Delegate
