import React, { useContext } from 'react'
import DelegateContext from '../context/DelegateContext'

function Final({ stepInfo, stepHandler }) {
  const { address, contracts, signer, provider } = useContext(DelegateContext)

  return <div>Final confirm page</div>
}

export default Final
