import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo.png'

import { FormattedMessage } from 'react-intl'

function Footer() {
  return (
    <footer className="relative">
      {/* <Cta /> */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px">
          {/* Footer illustration */}
          <div className="pointer-events-none -z-1" aria-hidden="true">
            <svg
              className="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
              width="800"
              height="264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="400"
                cy="400"
                r="400"
                fill="url(#footerglow_paint0_radial)"
                fillOpacity=".4"
              />
              <defs>
                <radialGradient
                  id="footerglow_paint0_radial"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="rotate(90 0 400) scale(315.089)">
                  <stop stopColor="#3ABAB4" />
                  <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                </radialGradient>
              </defs>
            </svg>
          </div>

          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-2 lg:col-span-3">
              {/* Logo */}
              <Link
                className="inline-flex items-center justify-center"
                to="/"
                aria-label="LightFTSO">
                <img
                  className="inline-block"
                  src={Logo}
                  alt=""
                  width={56}
                  height={56}
                />
                <span className="ml-2 dark h4 font-red-hat-display">
                  Light<span className="text-yellow-500">FTSO</span>
                </span>
              </Link>
            </div>

            {/* 2nd, 3rd, 4th and 5th blocks */}
            <div className="md:col-span-10 lg:col-span-9 grid grid-cols-1 md:grid-cols-4 gap-8">
              {/* 2nd block */}
              <div className="hidden md:block"></div>
              <div className="hidden md:block"></div>
              <div className="text-lg md:text-sm">
                <h6 className="font-medium uppercase mb-3">
                  <FormattedMessage
                    id="footer.section.title.resources"
                    defaultMessage="Resources"
                  />
                </h6>
                <ul>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://flare.network/"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.flare-networks"
                        defaultMessage="Flare Networks"
                      />
                    </a>
                  </li>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://flaremetrics.io/"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.flaremetrics"
                        defaultMessage="FlareMetrics"
                      />
                    </a>
                  </li>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://bifrostwallet.com/"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.bifrost-wallet"
                        defaultMessage="Bifrost Wallet"
                      />
                    </a>
                  </li>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="http://metamask.io/"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.metamask"
                        defaultMessage="MetaMask"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              {/* 3rd block */}
              <div className="text-lg md:text-sm">
                {/* <h6 className="font-medium uppercase mb-2">Resources</h6> */}
                <ul className="-mt-6 md:mt-8">
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://docs.flare.network/en/tutorials/wallets"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.wallet-tutorials"
                        defaultMessage="Wallet Tutorials"
                      />
                    </a>
                  </li>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://discord.com/invite/XqNa7Rq"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.flare-discord-channel"
                        defaultMessage="Flare Discord Channel"
                      />
                    </a>
                  </li>
                  <li className="mb-3 md:mb-1">
                    <a
                      className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                      href="https://www.npmjs.com/package/@flarenetwork/ftso_price_provider_kick_off_package"
                      target="_blank"
                      rel="noreferrer">
                      <FormattedMessage
                        id="footer.section.title.resources.link.make-your-own-ftso"
                        defaultMessage="Make your own FTSO"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <ul className="flex mb-4 md:order-2 md:ml-4 md:mb-0">
              <li>
                <a
                  className="flex justify-center items-center text-white bg-yellow-500 dark:text-yellow-500 dark:bg-gray-800 hover:underline hover:bg-yellow-600 rounded-full transition duration-150 ease-in-out"
                  href="https://twitter.com/lightFTSO"
                  aria-label="Twitter">
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="mailto:contact@lightft.so"
                  className="flex justify-center items-center p-2 text-white bg-yellow-500 dark:text-yellow-500 dark:bg-gray-800 hover:underline hover:bg-yellow-600 rounded-full transition duration-150 ease-in-out">
                  <svg
                    className="fill-current"
                    width={16}
                    height={16}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    enableBackground="new 0 0 30 30">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M27,4H3C1.9,4,1,4.9,1,6v1.3c0,0,0,0,0,0l14,8.4l14-8.4v0
				                c0,0,0,0,0,0V6C29,4.9,28.1,4,27,4z M1,11.9V25c0,1.1,0.9,2,2,2h24c1.1,0,2-0.9,2-2V11.9l-14,8.4L1,11.9z"
                    />
                  </svg>
                </a>
              </li>
            </ul>

            {/* Middle links */}
            <div className="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
              <Link
                className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="/disclaimer">
                Disclaimer
              </Link>{' '}
              ·{' '}
              <Link
                className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>

            {/* Copyrights note */}
            <div className="text-sm md:order-1 text-gray-700 mb-2 md:mb-0">
              <div className="text-gray-600 dark:text-gray-400 text-sm mr-4">
                &copy; 2022 <span className="text-yellow-500">LightFTSO</span>.{' '}
                <FormattedMessage
                  id="footer.copyright"
                  defaultMessage="All rights reserved."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
