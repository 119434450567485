import React from 'react'
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect'
import { useEthers } from '@usedapp/core'
import { UnsupportedChainIdError } from '@web3-react/core'

import BifrostWalletLogo from '../../images/bifrost-wallet.svg'
import GooglePlay from '../../images/googleplay.png'
import AppStore from '../../images/appstore.png'
import WrongNetwork from './WrongNetwork'

const BifrostWalletLink = () => {
  if (isAndroid) {
    return (
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.bifrostwallet.app"
          target="_blank"
          rel="noopener noreferrer">
          <img src={GooglePlay} alt="Download on Google Play" width={180} />
        </a>
      </div>
    )
  }
  if (isIOS) {
    return (
      <div>
        <a
          href="https://apps.apple.com/us/app/bifrost-wallet/id1577198351"
          target="_blank"
          rel="noopener noreferrer">
          <img src={AppStore} alt="Download on the AppStore" width={180} />
        </a>
      </div>
    )
  }

  return null
}

function EthereumCheck({ children }) {
  const {
    chainId,
    library,
    active: providerActive,
    error,
    activateBrowserWallet
  } = useEthers()

  if (typeof window.ethereum == 'undefined') {
    return (
      <>
        <BrowserView>
          <div className="flex flex-col px-4 md:px-2">
            <div className="text-1xl lg:text-2xl text-center text-gray-100 font-bold">
              Please install a Web3 provider like{' '}
              <a
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer"
                className="underline cursor-pointer">
                MetaMask
              </a>{' '}
              or use a Web3 compatible browser.
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="py-8 pb-12 px-3 flex flex-col justify-center items-center">
            <div className="font-semibold text-lg px-2 text-center">
              You seem to be on a mobile device. <br />
              Please use:
            </div>
            <div className="bg-white mt-6 pt-2 pb-3 px-6 rounded">
              <a
                href="https://bifrostwallet.com/"
                target="_blank"
                rel="noopener noreferrer">
                <img src={BifrostWalletLogo} alt="Bifrost Wallet" />
              </a>
            </div>
            <div className="mt-10">
              <BifrostWalletLink />
            </div>
          </div>
        </MobileView>
      </>
    )
  }

  if (error instanceof UnsupportedChainIdError) {
    console.error(error)
    return <WrongNetwork />
  }

  if (!providerActive) {
    return (
      <div className="flex flex-row items-center justify-center px-8">
        <button
          className="bg-green-400 text-white font-bold px-10 py-3 rounded pill"
          onClick={activateBrowserWallet}>
          Connect
        </button>
      </div>
    )
  }

  if (providerActive) {
    return <>{children}</>
  }

  // Other error handler
  return <WrongNetwork message={error?.message} />
}

export default EthereumCheck
