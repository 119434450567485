import React, { useContext, useState, useRef, useEffect } from 'react'

import { ethers } from 'ethers'

import { FlareContext } from './DappWrapper'
import { BigNumber } from '@ethersproject/bignumber'
import classNames from 'classnames'

import {
  useTokenBalance,
  useEthers,
  useEtherBalance,
  useContractCall,
  useContractFunction
} from '@usedapp/core'

import Spinner from '../../partials/Spinner'
import wsgbImage from '../../images/wsgb.png'
import sgbImage from '../../images/sgb.png'

import { roundDecimals } from './utils/roundDecimals'

import { addresses } from '../contracts'
import { networks } from '../const'
import { WnatAbi } from '../contracts/wnat'

function useWrapOrUnwrap(WnatContract) {
  const { state: wrapState, send: wrapTx } = useContractFunction(
    WnatContract,
    'deposit',
    {
      transactionName: 'Wrap'
    }
  )
  const { state: unwrapState, send: unwrapTx } = useContractFunction(
    WnatContract,
    'withdraw',
    {
      transactionName: 'Unwrap'
    }
  )

  const wrap = (amount) => {
    wrapTx({ value: ethers.utils.parseEther(amount) })
  }
  const unwrap = (amount) => {
    unwrapTx(ethers.utils.parseEther(amount))
  }

  return {
    loading: wrapState.status === 'Mining' || unwrapState.status === 'Mining',
    wrap,
    unwrap
  }
}

function Wrap() {
  const { contracts } = useContext(FlareContext)
  const { chainId, account } = useEthers()

  const [wrapOrUnwrap, setWrapOrUnwrap] = useState('wrap')

  const balance = useEtherBalance(account)
  const wnatBalance = useTokenBalance(addresses[chainId].Wnat, account)

  const [nativeSymbol] = useState(networks[chainId].nativeCurrency.symbol)
  const [wNativeSymbol] = useContractCall({
    abi: WnatAbi,
    address: addresses[chainId].Wnat,
    method: 'symbol',
    args: []
  }) ?? ['']

  const [convertInput, setConvertInput] = useState('')

  const [transactionFinishedMessage, setTransactionFinishedMessage] = useState('')

  const { loading: loadingTransactions, wrap, unwrap } = useWrapOrUnwrap(contracts?.Wnat)

  const sendTransaction = async () => {
    if (isNaN(convertInput) || convertInput <= 0) return

    switch (wrapOrUnwrap) {
      case 'wrap':
        wrap(convertInput.toString())
        break

      case 'unwrap':
        unwrap(convertInput.toString())
        break
    }
  }

  useEffect(() => {
    if (!contracts) return
  }, [contracts])

  if (!contracts || !balance || !wnatBalance)
    return (
      <div className="flex justify-center p-12">
        <Spinner size={32} />
      </div>
    )

  return (
    <div className="p-8 border-yellow-500 rounded border-2 w-96 mb-6">
      {/* Mobile lights switch */}
      <div className="flex flex-row justify-center px-2 mb-6 w-100">
        <button
          className={classNames(
            'font-bold text-lg w-1/2 inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-l text-white transition duration-150 ease-in-out disabled:opacity-50',
            wrapOrUnwrap === 'wrap'
              ? 'bg-yellow-300 hover:bg-yellow-300 cursor-default'
              : 'bg-yellow-500 hover:bg-yellow-400'
          )}
          disabled={wrapOrUnwrap === 'wrap'}
          onClick={() => [setWrapOrUnwrap('wrap'), setConvertInput('')]}>
          Wrap
        </button>
        <button
          className={classNames(
            'font-bold text-lg w-1/2 inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-r text-white transition duration-150 ease-in-out disabled:opacity-50',
            wrapOrUnwrap === 'unwrap'
              ? 'bg-yellow-300 hover:bg-yellow-300 cursor-default'
              : 'bg-yellow-500 hover:bg-yellow-400'
          )}
          disabled={wrapOrUnwrap === 'unwrap'}
          onClick={() => [setWrapOrUnwrap('unwrap'), setConvertInput('')]}>
          Unwrap
        </button>
      </div>
      <div className="">
        <div className='flex flex-row justify-center items-center mb-4 w-full'>
          <img
            src={wrapOrUnwrap === 'wrap' ? sgbImage : wsgbImage}
            alt=""
            width={24}
            height={24}
            className="mx-2 w-6 h-full"
          />
          <input
            className="mr-2 font-semibold bg-transparent border-0 border-b border-gray-500 focus:border-0 focus:ring-0 focus:border-gray-500 hide-spinners text-right text-xl outline-none focus:outline-none focus:border-0"
            type="text"
            inputMode="decimal"
            pattern="^[0-9]*[.,]?[0-9]*$"
            min="0"
            minLength="1"
            maxLength="18"
            max={wrapOrUnwrap === 'wrap' ? ethers.utils.formatEther(balance || 0) : ethers.utils.formatEther(wnatBalance || 0)}
            value={convertInput}
            placeholder={0}
            onChange={(e) => {
              if (!isNaN(e.target.value)) setConvertInput(e.target.value)
            }}
            onBlur={(e) => {
              let maxQty = parseFloat(
                ethers.utils.formatEther(
                  wrapOrUnwrap === 'wrap' ? balance?.toString() : wnatBalance?.toString()
                )
              )
              if (wrapOrUnwrap === 'wrap') {
                maxQty = maxQty - 1
              }
              if (parseFloat(e.target.value) >= maxQty) {
                setConvertInput(roundDecimals(maxQty))
              }
            }}
          />
          <button
            className="pointer bg-yellow-600 hover:bg-yellow-500 br-4 rounded font-bold px-2 py-1"
            onClick={() => {
              const max = parseFloat(
                ethers.utils.formatEther(
                  wrapOrUnwrap === 'wrap' ? balance?.toString() : wnatBalance?.toString()
                )
              )
              if (wrapOrUnwrap === 'wrap') {
                let maxWrap = max - 1

                setConvertInput(roundDecimals(maxWrap))
              } else if (wrapOrUnwrap === 'unwrap') {
                setConvertInput(roundDecimals(max))
              }
            }}>
            Max
          </button>
        </div>
        <div className="mb-4">
          <div className="text-sm text-gray-400">Do NOT wrap all of your SGB!</div>
          <span className="">Available: </span>
          <span className="font-semibold text-lg mr-1">
            {roundDecimals(
              ethers.utils.formatEther(
                wrapOrUnwrap === 'wrap' ? balance.sub(ethers.utils.parseUnits("1.0", "ether")) : wnatBalance?.toString()
            )
            )}
          </span>
          {wrapOrUnwrap === 'wrap' ? nativeSymbol : wNativeSymbol}
        </div>
      </div>
      <div className="text-center font-semibold text-xl px-2">
        <button
          className="font-bold text-xl h-12 w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out disabled:opacity-50"
          disabled={loadingTransactions}
          onClick={sendTransaction}>
          {loadingTransactions ? (
            <Spinner size={16} />
          ) : transactionFinishedMessage ? (
            transactionFinishedMessage
          ) : wrapOrUnwrap === 'wrap' ? (
            <span>
              Wrap
              <img
                src={wrapOrUnwrap === 'wrap' ? sgbImage : wsgbImage}
                alt=""
                width={24}
                height={24}
                className="inline mx-2 w-6 h-full"
              />
            </span>
          ) : (
            <span>
              Unwrap
              <img
                src={wrapOrUnwrap === 'wrap' ? sgbImage : wsgbImage}
                alt=""
                width={24}
                height={24}
                className="inline mx-2 w-6 h-full"
              />
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default Wrap
