import React, {
  useReducer,
  useRef
} from 'react'
import ViewSlider from 'react-view-slider'

import stepReducer from './reducers/stepReducer'

import Header from './Header'
import CheckWnatBalance from './steps/CheckWnatBalance'
import CheckDelegations from './steps/CheckDelegations'
import SelectDelegations from './steps/SelectDelegations'
import ConfirmDelegations from './steps/ConfirmDelegations'
import FinalStep from './steps/Final'

const steps = [
  {
    headerTitle: 'Balance',
    title: 'Wrap SGB',
    stepNumber: 1,
    Component: CheckWnatBalance
  },
  {
    headerTitle: 'Current',
    title: 'Current Delegations',
    stepNumber: 2,
    Component: CheckDelegations
  },
  {
    headerTitle: 'Select',
    title: 'Select Delegations',
    stepNumber: 3,
    Component: SelectDelegations
  },
  {
    headerTitle: 'Confirm',
    title: 'Confirm delegations',
    stepNumber: 4,
    Component: ConfirmDelegations
  },
  /* {
    headerTitle: 'Success',
    title: 'Success',
    stepNumber: 5,
    Component: FinalStep
  } */
]

const initialState = { step: 1 }

function Wizard(props) {
  const {
    onNextStep = () => { },
    onPrevStep = () => { },
    onSetStep = () => { }
  } = props

  const [{ step: currentStep }, dispatchStep] = useReducer(
    stepReducer,
    initialState
  )

  const { current: stepHandler } = useRef({
    next: () => [dispatchStep({ type: 'NEXT' }), onNextStep()],
    prev: () => [dispatchStep({ type: 'PREV' }), onPrevStep()],
    set: (step) => [dispatchStep({ type: 'SET', step }), onSetStep()]
  })

  const stepComponent = ({ index }) => {
    const Step = steps[index]
    return <Step.Component stepHandler={stepHandler} stepInfo={Step} />
  }

  return (
    <div>
      <Header
        steps={steps}
        currentStep={currentStep}
        stepHandler={stepHandler}
      />
      <ViewSlider
        renderView={stepComponent}
        numViews={steps.length}
        activeView={currentStep - 1}
      />
    </div>
  )
}

export default Wizard
