import React, { useContext, useEffect, useState, useCallback } from 'react'
import { ethers } from 'ethers'
import { useEthers, useContractFunction } from '@usedapp/core'

import DelegateContext from '../context/DelegateContext'
import Spinner from '../../../../partials/Spinner'
import { getProviderName } from '../../../const/knownProviders'
import { FlareContext } from '../../DappWrapper'

function CheckDelegations({ stepInfo, stepHandler }) {
  const { contracts } = useContext(FlareContext)
  const { account } = useEthers()
  const { knownProviders } = useContext(DelegateContext)

  const [delegates, setDelegates] = useState(null)
  const [delegationMode, setDelegationMode] = useState(null)
  const [delegationCount, setDelegationCount] = useState(null)
  const [wrappedBalance, setWrappedBalance] = useState(null)
  const [continueDisabled, setContinueDisabled] = useState(true)

  const [loading, setLoading] = useState(true)

  const init = async () => {
    const _delegates = await contracts.Wnat.delegatesOf(account)
    setDelegates(_delegates)
    setDelegationMode(_delegates._delegationMode.toNumber())
    setDelegationCount(_delegates._count.toNumber())
    setContinueDisabled((_delegates._count.toNumber() > 1 || _delegates._bips.reduce((a, b) => a.add(b), ethers.BigNumber.from(0)).toNumber() >= 10000))
    console.log(_delegates)

    const _wrappedBalance = await contracts.Wnat.balanceOf(account)
    setWrappedBalance(_wrappedBalance)

    setLoading(false)
  }

  const { state: undelegateAllTxState, send: undelegateAllTx } = useContractFunction(
    contracts?.Wnat,
    'undelegateAll',
    { transactionName: `Undelegate all` }
  )

  const txStatusMap = {
    None: '',
    Mining: 'Awaiting confirmation...',
    Success: 'Success!',
    Failed: 'Transaction unsuccesful',
    Exception: ''
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    undelegateAllTxState.status === 'Success' && init()
  }, [undelegateAllTxState.status])

  if (loading) {
    return (
      <div className="py-12 flex flex-col justify-center items-center">
        <Spinner size={36} />
      </div>
    )
  }

  /* if (delegationMode !== 1) {
    return (
      <div className="py-12 flex flex-col justify-center items-center">
        This app only supports Percentage Mode, it might support Explicit Mode some day in the far future!
      </div>
    )
  } */

  return (
    <div className="py-0 flex flex-col justify-center items-center">
      {delegates._delegateAddresses.map((delegate, i) => {
        const providerData = knownProviders.find(
          (p) => ethers.utils.getAddress(p.address) === ethers.utils.getAddress(delegate)
        ) || { name: getProviderName(delegate) }
        return (
          <div className="w-50 block my-4" key={delegate}>
            <span>
              {providerData?.logoURI && (
                <img
                  className="inline-block mr-2"
                  height={32}
                  width={32}
                  src={providerData?.logoURI}
                  alt={providerData?.name}
                />
              )}
            </span>
            <span className="font-semibold">{providerData.name}</span>
            <span className="ml-3 font-bold text-xl">
              {delegates._bips[i].toNumber() / 100}%{' '}
            </span>
            (
            <span className='font-semibold'>{' '}
              {ethers.utils.formatUnits(
                wrappedBalance
                  .mul(delegates._bips[i])
                  .div(ethers.BigNumber.from('10000000000000')),
                'gwei'
              )}
            </span> WSGB
            )
          </div>
        )
      })}
      <div className="text-xl mt-3 flex flex-col items-center justify-center w-full">
        <div className='mb-6'>
          {delegationCount > 0 ? (
            <div className="flex items-center justify-center w-full">
              Total delegations:
              <span className="font-bold text-xl mx-2">
                {delegates._delegateAddresses.length}
              </span>{' '}
              (max: 2)
              <span>
                <button
                  onClick={()=>undelegateAllTx()}
                  disabled={undelegateAllTxState.status === 'Mining'}
                  className="inline-block btn text-sm text-white w-44 h-8 ml-4 bg-red-500 hover:bg-red-600 w-24 flex items-center mt-2">
                  {undelegateAllTxState.status !== 'Mining' ? 'Undelegate all' : <Spinner size={16} />}
                </button>
              </span>
            </div>
          ) : (
            <div className="pb-4 text-2xl font-bold text-center">
              You have no delegations! <br />
              Set them up in the next step
            </div>
          )}
        </div>
        {continueDisabled &&
          <div className='font-semibold text-base mb-2 text-center'>
            Please undelegate to continue<br />You&lsquo;ll be able to select providers in the next step
          </div>}
        <button
          className={`btn text-white bg-gray-500 w-36 flex items-center ${!continueDisabled && 'bg-yellow-500 hover:bg-yellow-400'}`}
          onClick={stepHandler.next}
          disabled={delegates._bips.reduce((a, b) => a.add(b), ethers.BigNumber.from(0)).toNumber() >= 10000}>
          {'Continue'}
        </button>
      </div>
    </div>
  )
}

export default CheckDelegations
