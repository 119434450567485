import React from 'react'
import { Link } from 'react-router-dom'

import { useIntl, FormattedMessage } from 'react-intl'

const Checkmark = () => {
  return (
    <svg
      className="w-3 h-3 fill-current text-yellow-400 mr-2 flex-shrink-0"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
    </svg>
  )
}

function HeroHome() {
  const intl = useIntl()
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-10 md:pt-40 md:pb-16">
          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
            {/* Content */}
            <div className="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left">
              <h1
                className="h1 lg:text-4xl mb-4 font-red-hat-display font-bold"
                data-aos="fade-down">
                <FormattedMessage
                  id="home.hero.title"
                  defaultMessage="Delegate votes using your $FLR or $SGB tokens and earn rewards"
                />
              </h1>
              <p
                className="text-xl text-gray-600 dark:text-gray-400"
                data-aos="fade-down"
                data-aos-delay="150">
                <FormattedMessage
                  id="home.hero.subtitle1"
                  defaultMessage="The Flare network requires external price information to function."
                />
                <br />
                <FormattedMessage
                  id="home.hero.subtitle2"
                  defaultMessage="You can use your $FLR or $SGB tokens to help the network obtain accurate information from the external world."
                />
              </p>
              <ul
                className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2"
                data-aos="fade-down"
                data-aos-delay="450">
                <li className="flex items-center mb-2">
                  <Checkmark />
                  <span>
                    <FormattedMessage
                      id="home.hero.feature1"
                      defaultMessage="Generate passive income, risk-free, and opt-out any time."
                    />
                  </span>
                </li>
                <li className="flex items-center mb-2">
                  <Checkmark />
                  <span>
                    <FormattedMessage
                      id="home.hero.feature2"
                      defaultMessage="Help the network grow and function correctly."
                    />
                  </span>
                </li>
                <li className="flex items-center mb-2">
                  <Checkmark />
                  <span>
                    <FormattedMessage
                      id="home.hero.feature3"
                      defaultMessage="Keep custody of your tokens, they never leave your wallet."
                    />
                  </span>
                </li>
              </ul>
            </div>

            {/* Mobile mockup */}
            <div
              className="md:col-span-5 lg:col-span-5 text-center md:text-right"
              data-aos="fade-up"
              data-aos-delay="450">
              <div className="inline-flex flex-col relative justify-center items-center">
                {/* Glow illustration */}
                <svg
                  className="absolute mr-12 mt-32 pointer-events-none -z-1 dark:opacity-40"
                  aria-hidden="true"
                  width="678"
                  height="634"
                  viewBox="0 0 678 634"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="240"
                    cy="394"
                    r="240"
                    fill="url(#piphoneill_paint0_radial)"
                    fillOpacity=".4"
                  />
                  <circle
                    cx="438"
                    cy="240"
                    r="240"
                    fill="url(#piphoneill_paint1_radial)"
                    fillOpacity=".6"
                  />
                  <defs>
                    <radialGradient
                      id="piphoneill_paint0_radial"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="rotate(90 -77 317) scale(189.054)">
                      <stop stopColor="#667EEA" />
                      <stop offset="1" stopColor="#667EEA" stopOpacity=".01" />
                    </radialGradient>
                    <radialGradient
                      id="piphoneill_paint1_radial"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="rotate(90 99 339) scale(189.054)">
                      <stop stopColor="#9F7AEA" />
                      <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01" />
                    </radialGradient>
                  </defs>
                </svg>
                <Link
                  to="/delegate/flare"
                  className="font-bold text-xl w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out mt-8">
                  <FormattedMessage
                    id="home.hero.cta.flare"
                    defaultMessage="Delegate FLR (not available yet)"
                  />
                </Link>
                <Link
                  to="/delegate/songbird"
                  className="font-bold text-xl w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out mt-8">
                  <FormattedMessage
                    id="home.hero.cta.songbird"
                    defaultMessage="Delegate SGB"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroHome
