import React from 'react'

import Header from '../partials/Header'
import Footer from '../partials/Footer'
import EthereumCheck from '../ftso/dapps/EthereumCheck'
import DappWrapper from '../ftso/dapps/DappWrapper'
import { DAppProvider, Songbird } from '@usedapp/core'
import TopBar from '../ftso/dapps/TopBar'

const dAppConfig = {networks: [Songbird /*, Flare */]
}

function DappPage({ App }) {
  console.log(dAppConfig)
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />
      {/*  Page content */}

      <main className="flex flex-col justify-center items-center flex-grow pt-32">
        <div className="relative mx-auto flex flex-col items-center justify-center w-full px-6 lg:px-32">
          <DAppProvider config={dAppConfig}>
            <EthereumCheck>
              <DappWrapper>
                <TopBar />
                <App />
                <div className="w-full mt-16 mb-12 px-36">
                  <p className=''>
                    These tools are a work in progress, if you encounter any problems or want to propose a change or a new feature, please let us
                    know via{' '}
                    <a
                      className="font-semibold"
                      href="https://twitter.com/lightftso"
                      rel="noopener noreferrer"
                      target="_blank">
                      Twitter
                    </a>{' '}
                    or{' '}
                    <a
                      className="font-semibold"
                      href="mailto:contact@lightft.so"
                      rel="noopener noreferrer"
                      target="_blank">
                      Email
                    </a>
                  </p>
                </div>
              </DappWrapper>
            </EthereumCheck>
          </DAppProvider>
        </div>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default DappPage
