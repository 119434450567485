import React, { useContext, useEffect, useRef, useState } from 'react'
import { useEthers, useContractFunction } from '@usedapp/core'
import { ethers } from 'ethers'

import { networks, providers as knownProviders } from '../const'
import { FlareContext } from './DappWrapper'
import Spinner from '../../partials/Spinner'

function Rewards() {
  const { account, chainId } = useEthers()
  const { contracts } = useContext(FlareContext)

  const [pendingRewardsTotal, setPendingRewardsTotal] = useState(0)
  const [pendingRewardsList, setPendingRewardsList] = useState([])

  const [unclaimedRewardsTotal, setUnclaimedRewardsTotal] = useState(0)
  const [unclaimedRewardsList, setUnclaimedRewardsList] = useState([])

  const claimableEpochs = useRef([])

  const [transactionFinishedMessage, setTransactionFinishedMessage] = useState('')

  const { state: claimTxState, send: claimTx } = useContractFunction(
    contracts?.FtsoRewardManager,
    'claimReward',
    { transactionName: 'Claim Rewards' }
  )

  const claimRewards = () => {
    console.debug('Claiming rewards', {
      account,
      claimableEpochs: claimableEpochs.current
    })
    claimTx(account, claimableEpochs.current.slice(0, 13))
  }

  useEffect(() => {
    const clear = () => [
      setTimeout(() => {
        setTransactionFinishedMessage('')
      }, 3000)
    ]
    switch (claimTxState.status) {
      case 'Success':
        setTransactionFinishedMessage('Done')
        clear()
        init()
        break
      case 'Fail':
        setTransactionFinishedMessage('Transaction unsuccesful')
        clear()
        break
    }
  }, [claimTxState])

  const init = async () => {
    const currentRewardEpoch = await contracts.FtsoManager.getCurrentRewardEpoch()
    const pendingRewards = await contracts.FtsoRewardManager.getStateOfRewards(
      account,
      currentRewardEpoch
    )
    setPendingRewardsTotal(
      pendingRewards._rewardAmounts
        .map((reward) => parseFloat(ethers.utils.formatEther(reward)))
        .reduce((a, b) => a + b, 0)
    )
    setPendingRewardsList(
      pendingRewards._dataProviders.map((provider, i) => ({
        provider,
        value: parseFloat(ethers.utils.formatEther(pendingRewards._rewardAmounts[i]))
      }))
    )

    const epochsWithUnclaimedRewards =
      await contracts.FtsoRewardManager.getEpochsWithUnclaimedRewards(account)

    console.debug('epochsWithUnclaimedRewards', epochsWithUnclaimedRewards)
    const _unclaimedRewards = []
    for (let e = 0; e < epochsWithUnclaimedRewards.length; e++) {
      const epochId = epochsWithUnclaimedRewards[e]
      const rewards = await contracts.FtsoRewardManager.getStateOfRewards(
        account,
        epochId
      )
      if (rewards._claimable) {
        claimableEpochs.current.push(epochId)
        _unclaimedRewards.push(rewards)
        console.log('Claimable reward', rewards)
      }
    }
    console.log('_unclaimedRewards', _unclaimedRewards)
    const _unclaimedRewardList = []
    _unclaimedRewards.forEach((reward) => {
      return reward._dataProviders.forEach((provider, i) =>
        _unclaimedRewardList.push({
          provider,
          value: parseFloat(ethers.utils.formatEther(reward._rewardAmounts[i]))
        })
      )
    })
    console.log('_unclaimedRewardList', _unclaimedRewardList)
    setUnclaimedRewardsList(_unclaimedRewardList)
    const _unclaimedRewardsTotal = _unclaimedRewardList
      .map((reward) => parseFloat(reward.value))
      .reduce((a, b) => a + b, 0)
    setUnclaimedRewardsTotal(_unclaimedRewardsTotal)
  }

  useEffect(() => {
    if (!contracts) return
    init()
  }, [contracts])

  if (!contracts)
    return (
      <div className="flex justify-center p-12">
        <Spinner size={32} />
      </div>
    )

  return (
    <div className="flex flex-col justify-center items-center p-8 pt-4 md:px-4 lg:px-8 border-yellow-500 rounded border-2 mb-6 max-w-full">
      <div>
        <div className="pl-3">
          <div className="text-2xl font-semibold">Pending Rewards</div>
          {pendingRewardsList.length == 0 && (
            <div>You don&lsquo;t have any pending rewards</div>
          )}
          {pendingRewardsList.map((reward) => (
            <div className="text-lg" key={reward.provider}>
              <span>
                <span className="font-bold">{reward.value.toFixed(4)}</span>{' '}
                {networks[chainId].nativeCurrency.symbol} (
                <span className="font-bold">
                  {((reward.value / Number(pendingRewardsTotal)) * 100).toFixed(0)}%
                </span>
                ) from{' '}
                <span>
                  {knownProviders[reward.provider.toLowerCase()] ||
                    `${reward.provider.slice(0, 6)}..${reward.provider.slice(-2)}`}
                </span>
              </span>
            </div>
          ))}
        </div>
        {pendingRewardsTotal > 0 && (
          <div className="mt-2 font-medium text-xl pl-6">
            Total:{' '}
            <span className="font-bold">
              {pendingRewardsTotal.toFixed(4)} {networks[chainId].nativeCurrency.symbol}
            </span>
          </div>
        )}
        {unclaimedRewardsTotal > 0 && (
          <>
            <div className="mt-4 pl-3">
              <div className="text-2xl font-semibold">Available Rewards</div>
              {unclaimedRewardsList.map((reward) => (
                <div className="text-lg" key={reward?.provider + '_' + reward.value}>
                  <span>
                    <span className="font-bold">{reward?.value?.toFixed(4)}</span>{' '}
                    {networks[chainId].nativeCurrency.symbol} (
                    <span className="font-bold">
                      {((reward?.value / Number(unclaimedRewardsTotal)) * 100)?.toFixed(
                        0
                      )}
                      %
                    </span>
                    ) from{' '}
                    <span>
                      {knownProviders[reward?.provider?.toLowerCase()] ||
                        `${reward?.provider?.slice(0, 6)}..${reward?.provider?.slice(
                          -2
                        )}`}
                    </span>
                  </span>
                </div>
              ))}
            </div>
            <div className="flex flex-col justify-center mt-2">
              <div className="mt-2 font-medium text-xl pl-6">
                Total:{' '}
                <span className="font-bold">
                  {unclaimedRewardsTotal.toFixed(4)}{' '}
                  {networks[chainId].nativeCurrency.symbol}
                </span>
              </div>
              <button
                className="mt-6 mb-12 h-12 font-bold text-lg w-50 inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out"
                onClick={claimRewards}
                disabled={claimTxState.status === 'Mining'}>
                {claimTxState.status === 'Mining' ? (
                  <Spinner size={16} />
                ) : transactionFinishedMessage ? (
                  transactionFinishedMessage
                ) : (
                  <span>
                    Claim {unclaimedRewardsTotal.toFixed(4)}{' '}
                    {networks[chainId].nativeCurrency.symbol}
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Rewards
