const knownProviders = {
    "0x021305bb75b9d7f720fd328d137114c930917b62": "Flare Trusted #1",
    "0x651ccebfa2c5aa1e6d9c6180d91079f120314080": "Flare Trusted #2",
    "0x96b83d3f73e44c9a96388cf1d116595551daeb5a": "Flare Trusted #3",
    "0x69141e890f3a79cd2cff552c0b71508be23712dc": "Bifrost Oracle",
    "0x9a46864a3b0a7805b266c445289c3fad1e48f18e": "Bifrost Oracle",
    "0x4ed9e5b82ce66311ac2230d2fccc5202d7b8c083": "ScandiNodes FTSO",
    "0x6d323e71e141ce2d7b752313c8a654a9c9d1b377": "Aureus Ox",
    "0x9269fb79b098ab314de8a1e2afb8705678520443": "Aureus Ox",
    "0xbf61db1cdb43d196309824473fa82e5b17581159": "AlphaOracle",
    "0x47b6effe71abd4e8cdcc56f2341beb404f804b87": "AlphaOracle",
    "0x010a16c53f33e4d93892f00897965578b55a8cfc": "FTSO EU",
    "0xb0421af2cffb21d8a0be4087448146e4f9cbd306": "FTSO EU",
    "0xb9b7355f5b71cee345311921d247b1d2ba5cfe90": "FTSO UK",
    "0x5f911c2c681f678e5a3a8d54f950d6b192cc16e3": "FTSO UK",
    "0x499017adb21d6f70480e4e6224cf4144071c1461": "FTSO AU",
    "0x53caedda4339ed74272ecfef85b657def18fa2e4": "Use Your Spark",
    "0xa288054b230dcbb8689ac229d6dbd7df39203181": "Use Your Spark",
    "0x7394923453fc2f606cfb4d0ea1a5438bb8260d08": "Sun-Dara",
    "0x1e8f916ce03f4ce86186531a8994d366581ed4be": "Sun-Dara",
    "0x62d6116d6a139f2d402e8d8e30baaf5790542801": "Interoracle",
    "0xc9ac8f034d295962a6a975b717b691437605bbb6": "Lena Instruments",
    "0x2d7bf53ed6117ad1dcd6416d460481522a16afdf": "A-FTSO",
    "0x939789ed3d07a80da886a3e3017d665cbb5591dc": "Best FTSO",
    "0xca60cd408a5e447897258cdb1f699478c71cc55e": "Defi Oracles",
    "0xb53d69b2519ac9f6d65cff8e7824bf09f7064d61": "KysenPool",
    "0xddef7e54a9f72d9fc0a281b42f15a22b30681da5": "Optakt",
    "0x9565d813a3a0cea62b3bdb9a4e236dcb5910c4f0": "AFOracle",
    "0x33ddae234e403789954cd792e1febdbe2466adc2": "FTSOExpress",
    "0x819eab111bd9a6e595187a914240529d2efff21f": "HEWG",
    "0x17faba2995351176f648219a5a86921f9ed51a31": "Taco-Tso",
    "0xacb098842542b8decad0ea49ecd97d1410cbeb26": "Taco-Tso",
    "0x0fa72d3912d1c530ab1f6a8a9fb61c672947e964": "FTSO Cloud",
    "0x1b00870092a929d160492daf8e734b4bca033266": "Flare Oracle",
    "0xa467acee8127c55fb1f4d3b863ea5b0c4f599b9b": "HONO-TSO",
    "0xe60784d1c661a8d8ee19b442999bb71279f0a91f": "Big Baby FTSO",
    "0x92d6c2e99d5959f2e9c0a7aba5149d8a5ef22f23": "Oracle Daemon",
    "0x153ad30381b11dce62f349c97a54c2a58956b992": "SparkiFi",
    "0xe70d5351a842131c66aaebc4bd604912bf3cba72": "Scintilla",
    "0xfb9197720329a80191ba140844e96dccad149014": "LightFTSO",
    //"0x92d6c2e99d5959f2e9c0a7aba5149d8a5ef22f23": "Oracle Daemon",
    "0x4fe889f450eca4decd8a65b6b6ec5b7db8eab12e": "Oracle of Delphi",
    "0xe76bc13136338f27363425fcbcb36967b0540176": "Flare Kollectiv",
    "0x285430390a72ce038f6e54bf10f24b94a550474f": "Dione",
    "0x4f7f5f8ef4a3cc11f94e875393ee909eb5f824ea": "FlareFTSO",
    "0x58048528d3d3aea14ec95eb5e98b18de51780e27": "FlareFTSO",
    "0xaf31ca175bbe0c6dd667c8403b65a33b28238afa": "African Proofs",
    "0x59209585d87f5400209e93b18af513fa2f6cdea9": "Bitrue",
    "0x6ff5947ef89754682ad765b79c11f33452756d0b": "FTSO IT",
    "0x5f3c5991de3f0713715a733ee125785d516ceb16": "FlareFi",
    "0xf8b92be7ffb021b074f8621765d72da4c8862749": "dank FTSO",
    "0xd9200cc419bde28b169ad8c904d2687a15a4bf9f": "WitterFTSO",
    "0x8888888830a0fddaaa09ccd891ff5d35fb45d2a6": "BeamFTSO",
    //"0x462a18a1c8aae880be077e71b1cc208d32c31694": "Test 1",
    //"0x3ddd4a16b549280f67314f0e66b96b3dbf16ddcb": "Test 2",
    //"0xbd4ad52b5c2dbeef1c3de8ac4f7dafc9cd4e5cc0": "Test 3",
    //"0x285430390a72ce038f6e54bf10f24b94a550474f": "Unknown 1",
}

export const getProviderName = (provider) => {
    return knownProviders[provider.toLowerCase()] || `${provider.slice(0, 6)}...${provider.slice(-4)}`
}

export const getProviderData = async () => {
    return fetch('https://raw.githubusercontent.com/TowoLabs/ftso-signal-providers/master/bifrost-wallet.providerlist.json')
        .then(res => res.json())
        .then(res => Promise.resolve(res.providers))
        .catch(err => {
            console.error(err)
            return Promise.reject(err)
        })
}

export default knownProviders