import React from 'react'

import Header from '../partials/Header'
import Footer from '../partials/Footer'

function Disclaimer() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <h1 className="font-bold text-3xl">DISCLAIMER</h1>
              <p className="text-xl mt-10 mb-12">
                <span className="text-2xl font-bold">TL;DR</span>
                <span className="font-semibold">
                  : This site is provided as-is, and we make no warranties of
                  profits, returns or any other monetary gain or otherwise. We
                  make this service available on good faith but in the end it&lsquo;s
                  your decision and responsibility to participate in the
                  Flare/Songbird vote delegation process.
                </span>
              </p>
              <p>
                The site is provided on an as-is and as-available basis. you
                agree that your use of the site and our services will be at your
                sole risk. To the fullest extent permitted by law, we disclaim
                all warranties, express or implied, in connection with the site
                and your use thereof, including, without limitation, the implied
                warranties of merchantability, fitness for a particular purpose,
                and non-infringement. we make no warranties or representations
                about the accuracy or completeness of the site’s content or the
                content of any websites linked to the site and we will assume no
                liability or responsibility for any (1) errors, mistakes, or
                inaccuracies of content and materials, (2) personal injury or
                property damage, of any nature whatsoever, resulting from your
                access to and use of the site, (3) any unauthorized access to or
                use of our secure servers and/or any and all personal
                information and/or financial information stored therein, (4) any
                interruption or cessation of transmission to or from the site,
                (5) any bugs, viruses, trojan horses, or the like which may be
                transmitted to or through the site by any third party, and/or
                (6) any errors or omissions in any content and materials or for
                any loss or damage of any kind incurred as a result of the use
                of any content posted, transmitted, or otherwise made available
                via the site. we do not warrant, endorse, guarantee, or assume
                responsibility for any product or service advertised or offered
                by a third party through the site, any hyperlinked website, or
                any website or mobile application featured in any banner or
                other advertising, and we will not be a party to or in any way
                be responsible for monitoring any transaction between you and
                any third-party providers of products or services. as with the
                purchase of a product or service through any medium or in any
                environment, you should use your best judgment and exercise
                caution where appropriate.
              </p>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default Disclaimer
