import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from '../utils/Dropdown'
import Transition from '../utils/Transition.js'
import Logo from '../images/logo.png'

import { LocaleContext } from '../locale/LocaleContext'
import { useIntl, FormattedMessage } from 'react-intl'

function Header() {
  const [locale, setLocale] = useContext(LocaleContext)
  const intl = useIntl()

  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const trigger = useRef(null)
  const mobileNav = useRef(null)

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return
      //console.log(mobileNav.current.contains(target))
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setMobileNavOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  // Handle light modes
  /* const [darkMode, setDarkMode] = useState(() => {
    const dark = localStorage.getItem('dark-mode')
    if (dark === null) {
      return false
    } else {
      return dark === 'true'
    }
  }) */
  const [darkMode] = useState(true)

  useEffect(() => {
    localStorage.setItem('dark-mode', darkMode)
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  useEffect(() => {
    localStorage.setItem('dark-mode', darkMode)
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [locale])

  return (
    <header className="absolute w-full z-30 bg-black">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="flex items-center" aria-label="LightFTSO">
              <img src={Logo} alt="logo" width={48} height={48} />
              <span className="ml-3 text-lg font-bold">
                Light<span className="text-yellow-500">FTSO</span>
              </span>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow flex-wrap items-center font-medium">
              {/* <li>
                <Link
                  to="/about"
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  About
                </Link>
              </li> */}
              <li>
                <Link
                  to="/wrap"
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  <FormattedMessage
                    id="header.links.wrap"
                    defaultMessage="Wrap"
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="/rewards"
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  <FormattedMessage
                    id="header.links.rewards"
                    defaultMessage="Rewards"
                  />
                </Link>
              </li>
              {/*  <li>
                <Link
                  to="/feeds"
                  className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">
                  Price Feeds
                </Link>
              </li> */}
              {/* 1st level: hover */}
              <Dropdown
                title={intl.formatMessage({
                  id: 'header.links.delegate',
                  defaultMessage: 'Delegate'
                })}>
                {/* 2nd level: hover */}
                <li>
                  <Link
                    to="/delegate/songbird"
                    className="text-sm text-gray-600 dark:text-gray-400 hover:text-yellow-500 dark:hover:text-yellow-500 flex py-2 px-4 leading-tight">
                    <FormattedMessage
                      id="header.links.delegate.songbird"
                      defaultMessage="Songbird"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/delegate/flare"
                    className="text-sm text-gray-600 dark:text-gray-400 hover:text-yellow-500 dark:hover:text-yellow-500 flex py-2 px-4 leading-tight">
                    <FormattedMessage
                      id="header.links.delegate.flare"
                      defaultMessage="Flare (not available)"
                    />
                  </Link>
                </li>
              </Dropdown>
            </ul>

            {/* Desktop Locale Change */}
            <div className="focus-within:outline-yellow-500 flex flex-col justify-center ml-4">
              <select
                name="locale-switch"
                id="locale-switch-desktop"
                className="w-12 px-0 text-gray-200 font-bold bg-transparent text-center"
                value={locale}
                placeholder={locale}
                onChange={(e) => setLocale(e.target.value)}>
                <option value="en">EN</option>
                <option value="es">ES</option>
              </select>
            </div>
          </nav>

          {/* Mobile menu */}
          <div className="inline-flex md:hidden">
            {/* Desktop Locale Change */}
            <div className="focus-within:outline-blue flex flex-col justify-center -mt-0.5 mr-10">
              <select
                name="locale-switch"
                id="locale-switch-desktop"
                className="w-12 px-0 text-gray-200 font-bold bg-transparent text-center"
                value={locale}
                placeholder={locale}
                onChange={(e) => setLocale(e.target.value)}>
                <option value="en">EN</option>
                <option value="es">ES</option>
              </select>
            </div>

            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0">
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar">
                <div className="py-6 pr-4 pl-20">
                  {/* Logo */}
                  <div className="flex flex-row items-center justify-start w-100 mb-4">
                    <img src={Logo} width={48} height={48} alt="" />
                    <span className="ml-2 text-2xl font-bold">
                      Light<span className="text-yellow-500">FTSO</span>
                    </span>
                  </div>
                  {/* Links */}
                  <ul>
                    <li>
                      <Link
                        to="/wrap"
                        className="flex text-xl text-gray-600 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-100 py-2">
                        <FormattedMessage
                          id="header.links.wrap"
                          defaultMessage="Wrap"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/rewards"
                        className="flex text-xl text-gray-600 hover:text-gray-900 dark:text-gray-200 dark:hover:text-gray-100 py-2">
                        <FormattedMessage
                          id="header.links.rewards"
                          defaultMessage="Rewards"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/delegate/flare"
                        className="font-bold text-xl w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out mt-8">
                        <FormattedMessage
                          id="header.links.delegate.flare"
                          defaultMessage="Flare (not available)"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/delegate/songbird"
                        className="font-bold text-xl w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-yellow-500 hover:bg-yellow-400 transition duration-150 ease-in-out mt-8">
                        <FormattedMessage
                          id="header.links.delegate.songbird"
                          defaultMessage="Songbird"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
