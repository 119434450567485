export const addresses = {
  19: {
    FtsoManager: '0xbfA12e4E1411B62EdA8B035d71735667422A6A9e',
    FtsoRewardManager: '0xc5738334b972745067fFa666040fdeADc66Cb925',
    VoterWhitelister: '0xa76906EfBA6dFAe155FfC4c0eb36cDF0A28ae24D',
    PriceSubmitter: '0x1000000000000000000000000000000000000003',
    Wnat: '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED'
  },
  Songbird: {
    FtsoManager: '0xbfA12e4E1411B62EdA8B035d71735667422A6A9e',
    FtsoRewardManager: '0xc5738334b972745067fFa666040fdeADc66Cb925',
    VoterWhitelister: '0xa76906EfBA6dFAe155FfC4c0eb36cDF0A28ae24D',
    PriceSubmitter: '0x1000000000000000000000000000000000000003',
    Wnat: '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED'
  },

  14: {
    FtsoManager: '0xbfA12e4E1411B62EdA8B035d71735667422A6A9e',
    FtsoRewardManager: '',
    VoterWhitelister: '',
    PriceSubmitter: '',
    Wnat: ''
  },
  Flare: {
    FtsoManager: '0xbfA12e4E1411B62EdA8B035d71735667422A6A9e',
    FtsoRewardManager: '',
    VoterWhitelister: '',
    PriceSubmitter: '',
    Wnat: ''
  }
}
