import React, { useState } from 'react'
import ProviderEntry from './ProviderEntry'

function ProviderList({ availableProviders, network, lightFtsoAddress }) {
  const [search, setSearch] = useState('')

  if (!availableProviders || availableProviders.length == 0) {
    console.warn("No providers available! (maybe they weren't found in the list?)")
    return null
  }

  return (
    <div className="w-full flex flex-col items-center h-96 overflow-auto">
      <div className="text-center text-2xl font-semibold">Available providers <span className='text-base'>(select 1 or 2)</span></div>
      <div className="my-2 flex flex-col items-center justify-center w-full px-2">
        <input
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          className="w-full text-gray-900 rounded font-semibold"
        />
      </div>
      <div className='w-full border-solid border-2 border-slate-100 h-96 overflow-auto px-4 py-3 rounded'>
        <div className="w-full drop-shadow-sm">
          {(!search) && (
            <ProviderEntry
              provider={availableProviders.find(
                (p) => p?.address.toLowerCase() === lightFtsoAddress.toLowerCase()
              )}
              isLightFTSO={true}
            />
          )}
        </div>
        <div className="w-full flex flex-col items-center ">
          {availableProviders
            .sort((a, b) => {
              return a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : 0
            })
            .filter(
              (p) =>
                p.name.toLowerCase().includes(search.toLowerCase()) ||
                p.address.toLowerCase().includes(search.toLowerCase())
            )
            .filter((p) => p.chainId === network)
            .map((provider) => (
              <ProviderEntry key={provider.address} provider={provider} isLightFTSO={provider.address.toLowerCase() == lightFtsoAddress.toLowerCase()} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ProviderList
