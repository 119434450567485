/* eslint-disable react/display-name */
import React from 'react'

import Header from '../partials/Header'
import PageIllustration from '../partials/PageIllustration'
import HeroHome from '../partials/HeroHome'
import FeaturesBlocks from '../partials/FeaturesBlocks'
import Footer from '../partials/Footer'

import { useIntl, FormattedMessage } from 'react-intl'

function Home() {
  const intl = useIntl()

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />
        <>
          {/* Section header */}
          <div className="max-w-5xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="text-3xl font-bold font-raleway mb-10">
              <FormattedMessage
                id="home.content.title"
                defaultMessage="Homebrewed FTSO aiming for a high success rate and low fees."
              />
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              <FormattedMessage
                id="home.content.description"
                defaultMessage="A custom, home built Signal Provider for the Flare Time Series Oracle (FTSO). Our aim is to offer a <strong>trustworthy</strong> system with an exceptionally <strong>high success rate</strong>, helping the Flare Network grow, keeping our delegators <strong>happy</strong> and generating <strong>passive income</strong> for everyone who participates!"
                values={{ strong: (chunks) => <strong>{chunks}</strong> }}
              />
            </p>
          </div>
        </>
        <FeaturesBlocks />
        {/*  <Stats /> */}
        {/* <Carousel /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default Home
