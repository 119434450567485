import React, { useContext, useEffect, useState, useReducer } from 'react'

import DelegateContext from '../context/DelegateContext'
import { useEthers } from '@usedapp/core'


import ProviderList from './subcomponents/ProviderList'
import Selections from './subcomponents/Selections'
import selectedDelegationsReducer from '../reducers/selectedDelegationsReducer'
import SelectedDelegatesContext from '../context/SelectedDelegatesContext'


function SelectDelegations({ stepHandler }) {
  const { knownProviders, lightFtsoAddress, setSelectedDelegations, ...delegateContext } = useContext(DelegateContext)
  const { chainId } = useEthers()

  const [state, dispatch] = useReducer(selectedDelegationsReducer, [])

  const remaining = 100 - state.map(p => p.percent).reduce((a, b) => a + b, 0)

  return (
    <SelectedDelegatesContext.Provider value={{ state, dispatch }}>
      <div className="py-0 flex flex-col w-full justify-center items-center">
        <ProviderList
          availableProviders={knownProviders}
          network={chainId}
          lightFtsoAddress={lightFtsoAddress}
        />
        <Selections availableProviders={knownProviders} selectedDelegations={state} dispatch={dispatch} />

        {state.length > 0 &&
          <div className='flex flex-col items-center justify-center'>
            <div className='mt-2 text-lg'>Remaining: {remaining}%</div>
            {state.every(p => p.percent > 0)? <div className='mt-3'>
              <button
                className={`btn text-white text-xl w-48 flex items-center bg-yellow-500 hover:bg-yellow-400`}
                onClick={() => {
                  setSelectedDelegations(state)
                  stepHandler.next()
                }}>
                Continue
              </button>
            </div>:
            <div className='font-semibold text-base mb-2 text-center'>Please select a % for all your selected providers</div>}
          </div>}
      </div>
    </SelectedDelegatesContext.Provider>
  )
}

export default SelectDelegations
