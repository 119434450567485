import React from 'react'

const wrongNetworkMessage =
  'Unknown network selected. Please use the Songbird or Flare Networks in your Web3 provider'

function WrongNetwork({ message = wrongNetworkMessage }) {
  return (
    <div className="w-100 text-1xl lg:text-2xl text-center text-yellow-400 font-bold"></div>
  )
}

export default WrongNetwork
